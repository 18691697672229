<template>
  <div>
    <el-select
      v-model="port_id"
      :filterable="!isDefault"
      :size="size"
      :placeholder="placeTitle"
      remote
      :remote-method="remoteMethod"
      :clearable="clearable"
      :disabled="disabled"
      :loading="loading"
      :style="{ width: width }"
    >
      <el-option
        v-for="item in options"
        :key="item.id"
        :label="item.name_en"
        :value="item.id"
      >
        <div>[{{ item.code }}] {{ item.name_en }}</div>
      </el-option>
    </el-select>
  </div>
</template>

<script>
import { portList } from "~yst/utils/enumValue";
export default {
  name: "portSelect",
  props: {
    width: {
      type: String,
      default: "",
    },
    placeTitle: {
      type: String,
      default: "",
    },
    value: {
      type: String | Number,
      default: null,
    },
    size: {
      type: String,
      default: "small",
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isDefault: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      options: [
        // {
        //   city_name_en: "Shanghai",
        //   city_name_zh: "上海",
        //   code: "CNSHA",
        //   country_id: 1,
        //   country_name_en: "China",
        //   country_name_zh: "中国",
        //   id: 4179,
        //   is_base: 0,
        //   name_cn: "上海",
        //   name_en: "SHANGHAI",
        //   region: "CN",
        // }
      ],
      port_id: null,
      loading: false
    };
  },
  mounted() {
    if(this.isDefault){
      this.options = [{
        city_name_en: "Shanghai",
        city_name_zh: "上海",
        code: "CNSHA",
        country_id: 1,
        country_name_en: "China",
        country_name_zh: "中国",
        id: 4179,
        is_base: 0,
        name_cn: "上海",
        name_en: "SHANGHAI",
        region: "CN",
      },{
        city_name_en: "Shenzhen",
        city_name_zh: "深圳",
        code: "CNSZN",
        country_id: 1,
        country_name_en: "China",
        country_name_zh: "中国",
        id: 4189,
        is_base: 0,
        name_cn: "深圳",
        name_en: "SHENZHEN",
        region: "CN"
      },{
        city_name_en: "",
        city_name_zh: "",
        code: "CNYTN",
        country_id: 1,
        country_name_en: "China",
        country_name_zh: "中国",
        id: 4193,
        is_base: 0,
        name_cn: "深圳-盐田",
        name_en: "SHENZHEN,YANTIAN",
        region: "CN",
      },{
        city_name_en: "Ningbo",
        city_name_zh: "宁波",
        code: "CNNGB",
        country_id: 1,
        country_name_en: "China",
        country_name_zh: "中国",
        id: 4160,
        is_base: 0,
        name_cn: "宁波",
        name_en: "NINGBO",
        region: "CN",
      },{
        city_name_en: "Guangzhou",
        city_name_zh: "广州",
        code: "CNCAN",
        country_id: 1,
        country_name_en: "China",
        country_name_zh: "中国",
        id: 4121,
        is_base: 0,
        name_cn: "广州",
        name_en: "GUANGZHOU",
        region: "CN",
      },{
        city_name_en: "",
        city_name_zh: "",
        code: "CNHPU",
        country_id: 1,
        country_name_en: "China",
        country_name_zh: "中国",
        id: 4123,
        is_base: 0,
        name_cn: "广州,黄埔",
        name_en: "GUANGZHOU,HUANGPU",
        region: "CN"
      },{
        city_name_en: "",
        city_name_zh: "",
        code: "CHNNS",
        country_id: 1,
        country_name_en: "China",
        country_name_zh: "中国",
        id: 4124,
        is_base: 0,
        name_cn: "广州-南沙",
        name_en: "GUANGZHOU,NANSHA",
        region: "CN"
      },{
        city_name_en: "Qingdao",
        city_name_zh: "青岛",
        code: "CNTAO",
        country_id: 1,
        country_name_en: "China",
        country_name_zh: "中国",
        id: 4167,
        is_base: 0,
        name_cn: "青岛",
        name_en: "QINGDAO",
        region: "CN"
      },{
        city_name_en: "Tianjin",
        city_name_zh: "天津",
        code: "CNTSN",
        country_id: 1,
        country_name_en: "China",
        country_name_zh: "中国",
        id: 4206,
        is_base: 0,
        name_cn: "天津",
        name_en: "TIANJIN",
        region: "CN"
      },{
        city_name_en: "Dalian",
        city_name_zh: "大连",
        code: "CNDAL",
        country_id: 1,
        country_name_en: "China",
        country_name_zh: "中国",
        id: 4107,
        is_base: 0,
        name_cn: "大连",
        name_en: "DALIAN",
        region: "CN"
      },{
        city_name_en: "",
        city_name_zh: "",
        code: "CNDXG",
        country_id: 1,
        country_name_en: "China",
        country_name_zh: "中国",
        id: 4108,
        is_base: 0,
        name_cn: "大连新港",
        name_en: "DALIANXINGANG",
        region: "CN"
      }]
    }
    this.$nextTick(() => {
      this.port_id = this.value;
    });
  },
  watch: {
    port_id(nv) {
      let obj = this.options.find(item=>{
        return item.id == nv
      });
      this.$emit("input", nv, obj? obj.name_en : '');
    },
    value(nv){
        if(nv){
            this.handleInitValue()
        }
    }
  },
  methods: {
    selectClear(){
      this.options=[],
      this.port_id=null
    },
    async handleInitValue() {
      if (!this.isDefault && this.value != "") {
        let parmas = {};
        parmas.keyword = this.value;
        let res = await this.$store.dispatch("API_bookingSpace/getPortList", parmas);
          this.loading = false;
          if (res.success) {
            this.options = res.data;
            this.port_id=this.value
          }
      } else {
        this.port_id=this.value
      }
    },
    async remoteMethod(queryString) {
      if(this.isDefault){
        return;
      }
      this.loading = true
      let arr = await this.queryCompany(queryString)
      this.options = arr
    },
    async queryCompany(queryString) {
      try {
        let parmas = {};
        parmas.keyword = queryString
        parmas.nopage = 1
        parmas.limit = 20
        parmas.start = 0
        let data = await this.$store.dispatch('API_bookingSpace/getPortList', parmas);
        this.loading = false
        if (data.success) {
          return data.data
        }
      } catch {
      }
    },
  },
};
</script>

<style scoped>
</style>
